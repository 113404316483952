<template>
  <div class="box-detail" v-if="box">
    <CoinBar />

    <i class="share-icon" @click="showShare(box.bid)" />

    <BoxSwipe :items="box.items" />

    <div class="item-info">
      <div class="title van-multi-ellipsis--l3">{{ swipeActive.title }}</div>
      <div class="price">￥{{ swipeActive.price }}</div>
      <div class="level">
        <i class="itevel" :class="'itevel-' + swipeActive.rarity" />
        <span>{{ swipeActive.rarity | grade }}</span>
      </div>
      <!-- rarity: 3, //商品稀有度：0普通款; 1史诗款; 2传说款; 3神话款;-->
      <div class="opt">
        <van-button
          v-if="!iscode"
          @click="goTryIt()"
          type="info"
          round
          text="试玩"
        />
        <van-button type="info" class="in-cabinet-btn" to="/box/cabinet">
          盒柜
          <i class="badge" v-if="BoxBadge">{{ BoxBadge }}</i>
        </van-button>
      </div>
    </div>

    <!--本轮进度-->
    <van-button
      v-if="iscode"
      class="step-btn"
      @click="progress = true"
      type="warning"
    >
      查看本轮进度
    </van-button>
    <!--购买记录-->
    <div v-else>
      <div
        v-if="!notes || notes.length < 1"
        class="note-empty"
        @click="purchased = true"
      >
        购买记录
      </div>
      <van-notice-bar
        v-else
        :scrollable="false"
        @click="purchased = true"
        class="notice-bar"
      >
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          :touchable="false"
        >
          <van-swipe-item
            v-for="(note, i) in notes"
            :key="i"
            class="van-ellipsis"
            >{{ note }}</van-swipe-item
          >
        </van-swipe>
      </van-notice-bar>
    </div>
    <!--/购买记录-->

    <!--查看所有商品大按钮-->
    <div class="hug-btn">
      <van-button
        type="warning"
        block
        round
        icon="arrow-up"
        text="查看所有商品"
        @click="showItems = true"
      />
    </div>
    <!--/查看所有商品大按钮-->

    <!--所有商品-->
    <Carousel :items="box.items" />
    <!--/所有商品-->

    <!--抽取按钮-->
    <div class="btns-bar">
      <div v-if="box.is_limit === 1" class="btns-box">
        <van-button
          type="info"
          :text="'购买福袋 ￥' + box.price"
          @click="luckDraw(0)"
        />
      </div>
      <div v-else class="btns-box">
        <van-button
          type="info"
          :text="'单次抽取 ￥' + box.price"
          @click="luckDraw(0)"
        />
        <van-button
          v-if="!iscode"
          class="warn"
          :text="'五连抽 ￥' + box.bundle_price"
          @click="luckDraw(1)"
        />
      </div>
    </div>
    <!--/抽取按钮-->

    <!----------------------------------- 以下是条件显示 ----------------------------------->

    <!--全部商品展示-->
    <van-popup
      v-model="showItems"
      position="bottom"
      closeable
      round
      :style="{ height: '90%' }"
    >
      <div class="popup-content">
        <div class="popup-tit">全部商品详情</div>
        <van-row class="odds" gutter="10" v-if="!iscode">
          <van-col span="6" v-for="odd in odds" :key="odd.rarity">
            <span class="lvtxt">
              <i class="itevel" :class="'itevel-' + odd.rarity" />
              {{ odd.rarity | grade }}
            </span>
            <span class="tip">概率 {{ odd.per }}%</span>
          </van-col>
        </van-row>
        <van-list class="goods-list" :class="{ 'is-code-list': iscode }">
          <BoxItem
            :item="item"
            page="fav"
            v-for="item in box.items"
            :key="item.iid"
          />
        </van-list>
      </div>
    </van-popup>

    <!--商品详细展示-->
    <van-popup
      v-model="showGoodsDetail"
      position="bottom"
      closeable
      round
      @closed="goodsDetail = null"
      :style="{ height: '80%' }"
    >
      <div class="popup-content" v-if="goodsDetail">
        <div class="popup-tit">商品详情</div>
        <div class="boxg-detail">
          <div class="img-container">
            <img :src="goodsDetail.pic" />
          </div>
          <!-- <div
            v-for="(item, i) in goodsDetail.item_imgs"
            :key="i"
            class="img-container"
          >
            <img :src="item" />
          </div> -->
        </div>
      </div>
    </van-popup>

    <!--抽取欧气福袋-->
    <van-popup v-model="drawConfirm" position="bottom" closeable round>
      <div class="popup-content">
        <div class="popup-tit">确认支付</div>

        <div class="draw-box">
          <div class="draw-pic">
            <img :src="box.pic" />
          </div>
          <div class="draw-info">
            <div class="draw-tit van-ellipsis">{{ box.title }}</div>
            <div class="draw-tip">欧气福袋不支持7天无理由退货</div>
            <div class="price" v-if="bundle">
              <span class="mr-m">x5</span>
              ￥{{ box.bundle_price }}
            </div>
            <div class="price" v-else>￥{{ box.price }}</div>
          </div>
        </div>

        <!-- <van-cell title="优惠券" value="满500减5" :border="false" is-link /> -->
        <van-cell class="cell-agree" :border="false">
          <template #title>
            同意
            <span class="link" @click="showBuyerTips = true">{{
              buyerTipsTit
            }}</span>
          </template>
          <template #right-icon>
            <van-checkbox
              :value="agree"
              :checked-color="$global.checkedColor"
              @click="setAgree()"
              icon-size="16"
            />
          </template>
        </van-cell>
        <van-cell v-if="box.is_limit === 0" class="cell-agree" :border="false">
          <template #title>
            可抵扣金额
            <span class="link">￥{{ (Coin / 100) | towDecimalPlaces }}</span>
          </template>
          <template #right-icon>
            <van-checkbox
              :value="usecoin"
              :disabled="Coin <= 0"
              :checked-color="$global.checkedColor"
              @click="setUsecoin()"
              icon-size="16"
            />
          </template>
        </van-cell>
        <van-cell class="cell-agree" :border="false">
          <template #title>
            <span class="buytip">未成年人请在监护人陪同下购买</span>
          </template>
        </van-cell>

        <div class="end-pay">
          <van-button type="info" round @click="makeOrder()">
            <span>去支付</span>
            ￥{{ amount | towDecimalPlaces }}
          </van-button>
          <div v-if="deduct > 0" class="deduct">
            已抵扣金额 ¥{{ deduct | towDecimalPlaces }}
          </div>
        </div>
      </div>
    </van-popup>

    <!--购买须知-->
    <van-popup
      v-model="showBuyerTips"
      closeable
      :style="{ width: '90%', height: '90%' }"
      class="iframe-popup"
    >
      <StaticPage invoke="buyer" />
    </van-popup>

    <!--开盒记录-->
    <van-popup
      v-model="purchased"
      position="bottom"
      closeable
      round
      :style="{ height: '80%' }"
    >
      <div class="popup-content popup-purc">
        <div class="popup-tit">开盒记录</div>
        <Purchased :bid="box.bid" />
      </div>
    </van-popup>

    <!--本轮进度-->
    <van-popup
      v-model="progress"
      position="bottom"
      closeable
      round
      :style="{ height: '90%' }"
      class="round-step"
    >
      <div class="round-step-body">
        <div class="popup-tit">本轮进度</div>
        <RoundProgress :bid="box.bid" :takeAction="progress" mod="view" />
        <!-- takeAction的作用是告诉子组件开始加载数据 -->
      </div>
    </van-popup>

    <!--分享面板-->
    <Share v-if="box" invoke="box" :title="box.title" :img="box.pic_thumb" />
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import BoxSwipe from "@/components/BoxSwipe.vue";
import Share from "@/components/Share.vue";
import CoinBar from "@/components/CoinBar.vue";
import BoxItem from "@/components/BoxItem.vue";
import StaticPage from "@/components/StaticPage.vue";
import Purchased from "@/components/Purchased.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import Carousel from "@/components/Carousel.vue";

export default {
  name: "BoxDetail",
  components: {
    BoxSwipe,
    Share,
    CoinBar,
    BoxItem,
    StaticPage,
    Purchased,
    RoundProgress,
    Carousel,
  },
  data() {
    return {
      box: null,
      bid: null,
      swipeActive: null,
      goodsDetail: null,
      showItems: false,
      showGoodsDetail: false,
      drawConfirm: false,
      progress: false,
      purchased: false,
      showBuyerTips: false,
      agree: false, //同意条款
      iscode: false,
      odds: null,
      notes: null,
      HsliderOp: {
        width: 64, // 宽度
        height: 64, // 高度
        speed: 3000, // 间隔时间
      },
      buyerTipsTit: "《魔力Go福袋服务说明》",
      bundle: 0, //0是抽1次，1是5抽
      openedItems: null, //本轮进度
      roundItemsTotal: 0, //本轮进度盒子总数
      //支付金额相关
      usecoin: false, //用金币购买
      amount: 0, //显示的支付金额
      payment: 0, //提交给后台的支付金额
      deduct: 0, //抵扣金额
    };
  },
  computed: {
    Coin() {
      return this.$store.state.Coin;
    },
    BoxBadge() {
      return this.$store.state.BoxBadge;
    },
  },
  created() {
    this.bid = this.$route.params.id;
    this.getDetail();
    //购买须知和使用金币购买：第一次不勾，勾了以后记录
    let lsUsecoin = localStorage.getItem("usecoin");
    this.usecoin = lsUsecoin === "true" ? true : false;
    let lsAgree = localStorage.getItem("agree");
    this.agree = lsAgree === "true" ? true : false;
  },
  methods: {
    setAgree() {
      this.agree = !this.agree;
      localStorage.setItem("agree", this.agree);
    },
    setUsecoin() {
      this.usecoin = !this.usecoin;
      localStorage.setItem("usecoin", this.usecoin);
      this.calculated();
    },
    makeOrder() {
      if (!this.agree) {
        this.$toast(`请先阅读并同意${this.buyerTipsTit}`);
        return false;
      }
      let use_coin = this.usecoin ? 1 : 0;
      this.$ajax
        .post("/order/trade/box/", {
          bid: this.box.bid,
          bundle: this.bundle,
          payment: this.payment,
          use_coin: use_coin,
        })
        .then((res) => {
          let data = { ...res.data, ...{ is_limit: this.box.is_limit } };
          this.$router.push({
            name: "Pay",
            params: { data: data },
          });
        });
    },
    luckDraw(bundle) {
      //如果是新手盲盒
      if (this.box.is_limit === 1 && this.box.limit_count < 1) {
        this.$toast("已经购买过了，不要太贪心哦~");
        return false;
      }
      //
      this.bundle = bundle;
      //抽1个还是5个
      let many = bundle === 0 ? 1 : 5;
      let count = this.BoxBadge * 1;
      if (count + many > 99) {
        this.$toast("盒柜已满，无法再抽取了哦~");
        return false;
      }
      this.drawConfirm = true;
      this.calculated();
    },
    calculated() {
      if (this.Coin <= 0) this.usecoin = false;
      this.amount = this.bundle ? this.box.bundle_price : this.box.price;
      this.payment = this.amount;
      //使用余额和不用余额
      if (this.usecoin) {
        if (this.Coin / 100 > this.amount) {
          this.deduct = this.amount;
          this.amount = 0;
        } else {
          this.amount = this.amount - this.Coin / 100;
          this.deduct = this.Coin / 100;
        }
      } else {
        this.deduct = 0;
        this.amount = this.payment;
      }
    },
    goTryIt() {
      let rares = this.box.items.filter((item) => {
        return item.rarity > 0;
      });
      //如果没有稀有物品
      let rare =
        rares.length < 1
          ? this.box.items[0]
          : rares[Math.floor(Math.random() * rares.length)];
      //随机获取一项

      this.$router.push({
        name: "Draw",
        params: {
          quantity: 0,
          items: [rare],
        },
      });
    },
    openGoods(item) {
      this.showGoodsDetail = true;
      this.goodsDetail = item;
    },
    showShare(id) {
      Bus.$emit("showShareOnbus", id);
    },
    getDetail() {
      this.$ajax
        .post("/market/box/get/", { bid: this.bid }, "loading")
        .then((res) => {
          this.box = res.data;
          this.swipeActive = this.box.items[0];
          this.notes = res.data.tips;
          //重新排序odds，从rarity3~0高到低排序
          this.odds = this.box.odds.sort((a, b) => {
            return b.rarity - a.rarity;
          });
          //把盲盒里的所有商品存到store，抽奖时Marquee滚动需要用到
          this.$store.commit("Carefree", res.data.items);
          //type = 0普通盲盒；1密码盲盒
          this.iscode = this.box.type === 1 ? true : false;
          //新手盲盒不能抵扣金额
          if (this.box.is_limit === 1) {
            this.usecoin = false;
          }
        });
    },
  },
};
</script>
