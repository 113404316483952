<template>
  <div class="bbox">
    <div class="hd">
      <router-link class="clearfix" to="#">
        <img :src="hdImg" />
      </router-link>
      <span class="rule-btn" @click="showBuyerTips = true">
        <van-icon name="question-o" />
        规则
      </span>
      <CoinBar v-if="geted" />
    </div>

    <van-row gutter="10" class="bnav">
      <van-col span="8">
        <div class="btn" @click="keyboard = true">
          <span>密码福袋</span>
        </div>
      </van-col>
      <van-col span="8">
        <router-link class="btn task" to="/task">
          <span>任务中心</span>
        </router-link>
      </van-col>
      <van-col span="8">
        <router-link class="btn cabinet" to="/box/cabinet">
          <span>盒柜</span>
        </router-link>
      </van-col>
    </van-row>

    <!--公告-->
    <div class="tips">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
          vertical
        >
          <van-swipe-item
            v-for="(tip, i) in tips"
            :key="i"
            class="van-ellipsis"
          >
            {{ tip }}
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <!--/公告-->

    <!--轮播-->
    <Swipe :ads="ads" />

    <!--欧气福袋列表-->
    <van-list
      v-model="loading"
      :finished="finished"
      @load="getList"
      class="bbox-list"
    >
      <router-link
        v-for="item in lists"
        :key="item.bid"
        :to="{ name: 'BoxDetail', params: { id: item.bid } }"
      >
        <LuckBag :item="item" />
      </router-link>
    </van-list>
    <!--/欧气福袋列表-->

    <!--欧气福袋密码输入框-->
    <van-dialog
      v-model="keyboard"
      title="请输入福袋密码"
      show-cancel-button
      :overlay="false"
      :closeOnClickOverlay="false"
      :before-close="beforeClosePbox"
      class="dialog-code"
    >
      <van-password-input
        :value="code"
        :gutter="5"
        :focused="keyboard"
        @focus="keyboard = true"
        maxlength="6"
        length="6"
      />
    </van-dialog>
    <!-- 数字键盘 -->
    <van-number-keyboard
      v-model="code"
      :show="keyboard"
      :hide-on-click-outside="false"
      @blur="keyboard = false"
    />
    <!--/欧气福袋密码-->

    <!--欧气福袋规则-->
    <van-popup
      v-model="showBuyerTips"
      closeable
      :style="{ width: '90%', height: '90%' }"
      class="iframe-popup"
    >
      <StaticPage invoke="rule" />
    </van-popup>
  </div>
</template>

<script>
import StaticPage from "@/components/StaticPage.vue";
import CoinBar from "@/components/CoinBar.vue";
import Swipe from "@/components/Swipe.vue";
import LuckBag from "@/components/LuckBag.vue";
export default {
  name: "Box",
  components: {
    StaticPage,
    CoinBar,
    Swipe,
    LuckBag,
  },
  data() {
    return {
      keyboard: false,
      code: "",
      showBuyerTips: false,
      hdImg: null,
      quests: [],
      ads: [],
      tips: [],
      lists: [],
      page: 0,
      loading: false,
      finished: false,
      geted: false, //判断请求过数据了没有
    };
  },
  watch: {
    code(val) {
      console.log(val.length);
      if (val.length > 6) {
        this.code = val.slice(0, 6);
      }
    },
  },
  created() {
    this.getBox();
  },
  methods: {
    beforeClosePbox(action, done) {
      if (action === "confirm") {
        if (!this.code || this.code.length < 6 || this.code.length > 6) {
          this.$toast("请输入6位福袋密码");
          done(false);
        } else {
          this.$ajax
            .post("/market/box/jump/", { box_code: this.code }, "loading")
            .then((res) => {
              this.$router.push({
                name: "BoxDetail",
                params: { id: res.data.bid, mod: "code" },
              });
            })
            .catch((res) => {
              this.code = "";
              done(false);
            });
        }
      } else {
        this.code = "";
        done();
      }
    },
    getBox() {
      this.$ajax.post("/market/box/index/", {}, "loading").then((res) => {
        this.$store.commit("Coin", res.coin);
        this.hdImg = res.logo;
        this.ads = res.ads;
        this.tips = res.tips;
        this.geted = true;
      });
    },
    getList() {
      this.$ajax
        .post("/market/box/list/", {
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          this.loading = false;
          if (this.page === 0) {
            this.lists = res.data;
          } else {
            if (res.data) {
              this.lists = [...this.lists, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
        });
    },
  },
};
</script>
