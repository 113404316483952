<template>
  <div class="coin">
    <i class="icon-coin" />
    <span>{{ Coin | diamond }}</span>
  </div>
</template>


<script>
export default {
  name: "CoinBar",
  computed: {
    Coin() {
      return this.$store.state.Coin;
    },
  },
};
</script>