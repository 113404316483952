<template>
  <div class="inbox-bar" ref="inbox">
    <div
      v-for="index in 3"
      :key="index"
      :class="`group${index} inbox-container`"
      :style="{
        'animation-duration': `${duration}ms`,
        'animation-delay': index === 3 ? `${duration / 2}ms` : 0,
      }"
    >
      <div class="inbox-item" v-for="(item, i) in items" :key="i">
        <img :src="item.pic" />
        <span class="lv-badge" :class="'itevel-' + item.rarity">
          {{ item.rarity | grade }}款
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    items: Array,
  },
  data() {
    return {
      duration: 0,
      itemWidth: 73, // 64+9=73 | .inbox-item margin-left: 9px; img width 64px;
      distance: 0,
      windowWidth: document.documentElement.clientWidth,
      //
      groupWidth: this.itemWidth * 6, //因为至少是6个
    };
  },
  created() {
    this.duration = (this.items.length - 6 + 8) * 1500; //默认6个是8秒动画，之后每增加一个就增加1.5秒
    // console.log("this.items.length", this.items.length);
    // console.log("this.duration", this.duration);
    //至少是6个起，如果大雨6个要重新计算每组宽度
    if (this.items.length > 5) {
      this.groupWidth = this.itemWidth * this.items.length;
    }
  },
  mounted() {
    // let distance = this.containerWidth - this.windowWidth + 10;
    // this.$nextTick(() => {
    //   this.distance = distance;
    //   if (distance > 0) {
    //     setInterval(() => {
    //       this.distance = this.distance === 0 ? distance : 0;
    //     }, this.duration + 1500);
    //   }
    // });
  },
  methods: {},
};
</script>
