<template>
  <router-link
    :to="{
      name: 'BoxDetail',
      params: { id: item.bid, mod: mod ? 'code' : null },
    }"
    :class="mod ? 'codebox-item' : 'boxhome-item'"
  >
    <van-cell span="12" class="item clearfix">
      <div class="cover"><img :src="item.pic" /></div>
      <div class="title van-ellipsis">{{ item.title }}</div>

      <van-row class="level" v-if="mod">
        <span class="muted">
          <em>{{ rarityNum() }}</em> 款稀有
        </span>
        <span class="muted">
          共 <em>{{ item.num }}</em> 款
        </span>
      </van-row>
      <!-- <van-row class="level" v-else>
        <van-col
          span="6"
          v-for="odd in item.odds.slice(0).reverse()"
          :key="odd.rarity"
        >
          <i class="itevel" :class="`itevel-${odd.rarity}`" />
          x{{ odd.num }}
        </van-col>
      </van-row> -->

      <div class="price">￥{{ item.price }}</div>

      <div v-if="mod" class="open">密码：{{ item.code }}</div>
      <div v-else class="open">{{ item.open_num }}人已购买</div>

      <div class="exp">
        <div class="imgs">
          <img :src="img" v-for="(img, i) in item.item_imgs" :key="i" />
        </div>
      </div>
      <span v-if="item.odds.rarity > 0" class="badge bang">爆款</span>
      <span v-else-if="item.badge === 1" class="badge">热门</span>
      <span v-else-if="item.badge === 2" class="badge new">新品</span>
    </van-cell>
    <van-icon
      v-if="mod"
      name="delete-o"
      class="del-btn"
      @click.prevent="delbox(item)"
    />
  </router-link>
</template>

<script>
export default {
  name: "LuckBag",
  props: {
    mod: { type: String, default: "" },
    item: Object,
  },
  data() {
    return {};
  },
  methods: {
    rarityNum(rarity) {
      let count = 0;
      this.item.odds.forEach((odd) => {
        if (rarity) {
          if (odd.rarity === rarity) count++;
        } else {
          if (odd.rarity > 0) count++;
        }
      });
      return count;
    },
    delbox(item) {
      this.$parent.delbox(item);
    },
  },
};
</script>
