const filters = {
  hideword: (username) => {
    let hidename;
    if (username.length > 2) {
      hidename = username.slice(0, 1) + "**" + username.slice(-1);
    } else {
      hidename = username.slice(0, 1) + "***";
    }
    return hidename;
  },
  grade: (sort, after) => {
    let grades = ["普通", "史诗", "传说", "神话"];
    let text = after ? after : "";
    return sort < 0 ? "全部" : grades[sort] + text;
  },
  diamond: (coin) => {
    let rmb = coin;
    return rmb >= 1e3 && rmb < 1e4
      ? (rmb / 1e3).toFixed(2) + "K"
      : rmb >= 1e4 && rmb < 1e7
      ? (rmb / 1e4).toFixed(2) + "W"
      : rmb >= 1e7 && rmb < 1e8
      ? (rmb / 1e7).toFixed(2) + "KW"
      : rmb >= 1e8
      ? (rmb / 1e8).toFixed(2) + "E"
      : rmb;
  },
  towDecimalPlaces: (x) => {
    var f = parseFloat(x);
    if (isNaN(f)) {
      return false;
    }
    var f = Math.round(x * 100) / 100;
    var s = f.toString();
    var rs = s.indexOf(".");
    if (rs < 0) {
      rs = s.length;
      s += ".";
    }
    while (s.length <= rs + 2) {
      s += "0";
    }
    return s;
  },
  userIconFix: (url) => {
    let nUrl;
    if (url) {
      let pre2 = url.slice(0, 2);
      nUrl =
        pre2 !== "ht" &&
        pre2 !== "bl" &&
        pre2 !== "da" &&
        pre2 !== "im" &&
        pre2 !== ".." &&
        pre2 !== "./"
          ? "https://" + url
          : url;
    } else {
      nUrl = require("../assets/img/avatar.png");
    }
    return String(nUrl);
  },
  formatDate: (stamp, mod) => {
    if (!stamp) {
      return "";
    }
    let dateTxt;
    let stamp13 = stamp.toString().length > 10 ? stamp : stamp * 1000;
    let date = new Date(stamp13);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;

    let now = new Date();
    let today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let yestday = new Date(today - 24 * 3600 * 1000).getTime();

    switch (mod) {
      case "D":
        dateTxt = date.getDate();
        break;
      case "M":
        dateTxt = date.getMonth() + 1 + "月";
        break;
      case "ms":
        dateTxt = m + ":" + s;
        break;
      case "ymd":
        dateTxt = y + "-" + MM + "-" + d;
        break;
      case "ymdcn":
        dateTxt = y + "年" + MM + "月" + d + "日";
        break;
      case "hms":
        dateTxt = h + ":" + m + ":" + s;
        break;
      case "hm":
        dateTxt = h + ":" + m;
        break;
      case "mdhms":
        dateTxt = MM + "-" + d + " " + h + ":" + m + ":" + s;
        break;
      case "mdhm":
        dateTxt = MM + "-" + d + " " + h + ":" + m;
        break;
      case "md":
        dateTxt = date.getMonth() + 1 + "月" + d + "日";
        break;
      case "chat":
        if (stamp13 > yestday) {
          dateTxt = h + ":" + m;
        } else {
          dateTxt = MM + "/" + d;
        }
        break;
      default:
        dateTxt = y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    }
    return dateTxt;
  },
};

export default filters;
