<template>
  <div class="task">
    <div class="hd">
      <h2>七日签到</h2>
      <div class="tip">连续7日签到，赢得丰厚奖励</div>
      <van-row class="days">
        <van-col
          span="6"
          v-for="(item, i) in days"
          :offset="item.day === 5 ? 3 : ''"
          :key="item.day"
        >
          <div class="check" :class="{ checked: item.chk_time > 0 }">
            <div class="circle">
              <i v-if="item.reward_type === 1" class="icon-coin" />
              <i v-else class="icon-rmb" />
              <span class="num">{{ item.reward_num }}</span>
            </div>
          </div>
          <span class="label">{{ dayscn[i] }}</span>
        </van-col>
      </van-row>
      <div class="end">
        <van-button
          plain
          type="danger"
          @click="ckeckin"
          :disabled="checked"
          :text="checked ? '已签到' : '签 到'"
        />
      </div>
    </div>
    <!--Task-main-->
    <div class="task-main">
      <van-tabs class="tabs" type="card">
        <van-tab
          v-for="tab in tabs"
          :title="tab.tab_name"
          :key="tab.type"
          :name="tab.type"
        >
          <van-empty
            v-if="!tab.data || tab.data.length < 1"
            description="没有任务"
          />

          <van-cell-group v-else inset>
            <van-cell v-for="task in tab.data" :key="task.quest_id">
              <div class="icon"><img :src="task.icon" /></div>
              <div class="info">
                <div class="van-ellipsis">{{ task.title }}</div>
                <div class="desc van-ellipsis">{{ task.desc }}</div>
              </div>
              <div class="exp">
                <button v-if="task.status === 2" class="goted" disabled>
                  已领取
                </button>
                <button
                  v-else
                  @click="getReward(task.quest_id)"
                  :disabled="task.status !== 1"
                >
                  领取
                  <i v-if="task.reward_type === 1" class="icon-coin" />
                  <i v-else class="icon-rmb" />
                  {{ task.reward_num }}
                </button>
                完成 {{ task.progress }}/{{ task.total }}
              </div>
            </van-cell>
          </van-cell-group>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Task",
  data() {
    return {
      checked: false,
      dayscn: [
        "第一日",
        "第二日",
        "第三日",
        "第四日",
        "第五日",
        "第六日",
        "第七日",
      ],
      tabs: [
        {
          type: 1,
          tab_name: "新手任务",
          data: [],
        },
        {
          type: 2,
          tab_name: "每日任务",
          data: [],
        },
        {
          type: 4,
          tab_name: "成就任务",
          data: [],
        },
      ],
      days: [],
    };
  },
  computed: {
    TaskBadge() {
      return this.$store.state.TaskBadge;
    },
  },
  created() {
    this.getTask();
    // console.log("type: 1", this.tabIndex(1));
    // console.log("type: 2", this.tabIndex(2));
    // console.log("type: 4", this.tabIndex(4));
  },
  methods: {
    tabIndex(type) {
      return this.tabs.findIndex((item) => {
        return item.type === type;
      });
    },
    lessenBadge() {
      if (this.TaskBadge > 0) {
        this.$store.commit("TaskBadge", this.TaskBadge - 1);
      }
    },
    getTask() {
      this.$ajax.post("/quest/quest/index/", {}, "loading").then((res) => {
        let tasks = res.data;
        tasks.forEach((task) => {
          if (task.type === 1) {
            this.tabs[0].data.push(task);
          } else if (task.type === 2) {
            this.tabs[1].data.push(task);
          } else if (task.type === 4) {
            this.tabs[2].data.push(task);
          }
          // 这样可能会太慢
          // let index = this.tabIndex(task.type);
          // this.tabs[index].data.push(task);
        });
        this.checked = res.today_chk > 0;
        this.days = res.daily_chks;
      });
    },
    ckeckin() {
      this.$ajax
        .post("/quest/daily/check/", {})
        .then((res) => {
          if (res.ret === 0) {
            this.$toast.success("签到成功");
            this.checked = true;
            this.days = res.daily_chks;
            this.$store.commit("Coin", res.coin);
          } else {
            this.$toast(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
          //this.$toast(String(error));
        });
    },
    getReward(id) {
      this.$ajax
        .post("/quest/quest/collect/", { quest_id: id }, "loading")
        .then((res) => {
          //获取任务的tab的index
          let index = this.tabIndex(res.quest.type);
          //获取任务的列表
          let data = this.tabs[index].data;
          for (let i = 0; i < data.length; i++) {
            const task = data[i];
            if (task.quest_id === id) {
              this.$set(data, i, res.quest);
              break;
            }
          }
          this.$store.commit("Coin", res.coin);
          this.$toast.success("已领取奖励");
          this.lessenBadge(); //减少角标
        });
    },
  },
};
</script>
