<template>
  <div class="my">
    <div v-if="User" class="hd">
      <div class="avatar">
        <img :src="User.icon | userIconFix" />
      </div>
      <div class="uinfo">
        <div class="van-ellipsis">
          {{ User.username }}
        </div>
        <div class="van-ellipsis">ID：{{ User.uid }}</div>
      </div>
      <van-button :to="{ name: 'Profile' }">编辑资料</van-button>
    </div>

    <div class="items">
      <div class="item">
        <div class="tit van-hairline--bottom">
          我的订单
          <div class="more">
            <router-link :to="{ name: 'Orders', params: { tabid: -1 } }"
              >查看全部</router-link
            >
          </div>
        </div>
        <!-- <van-row type="flex" justify="space-around"> -->
        <van-row>
          <van-col span="6">
            <router-link :to="{ name: 'Orders', params: { tabid: 1 } }">
              <i class="my-icon icon-pay" />
              <span>待付款</span>
            </router-link>
          </van-col>
          <!-- <van-col span="6">
            <router-link :to="{ name: 'Orders', params: { tabid: 2 } }">
              <i class="my-icon icon-send" />
              <span>待发货</span>
            </router-link>
          </van-col> -->
          <van-col span="6">
            <router-link :to="{ name: 'Orders', params: { tabid: 3 } }">
              <i class="my-icon icon-receipt" />
              <span>待收货</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Orders', params: { tabid: 4 } }">
              <i class="my-icon icon-order" />
              <span>已完成</span>
            </router-link>
          </van-col>
          <!-- <van-col>
            <router-link :to="{ name: 'Orders', params: { tabid: 5 } }">
              <i class="my-icon icon-refund" />
              <span>待退货</span>
            </router-link>
          </van-col> -->
        </van-row>
      </div>

      <div class="item">
        <div class="tit van-hairline--bottom">我的工具</div>
        <van-row>
          <van-col span="6">
            <router-link :to="{ name: 'Favorite' }">
              <i class="my-icon icon-collect" />
              <span>收藏</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <van-badge :content="NoteBadge || null">
              <router-link :to="{ name: 'Note' }">
                <i class="my-icon icon-message" />
                <span>信息</span>
              </router-link>
            </van-badge>
          </van-col>
          <van-col span="6">
            <div class="my-nav-services-btn">
              <i class="my-icon icon-service" />
              <span>客服</span>
              <ServicesBtn />
            </div>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Cabinet' }">
              <i class="my-icon icon-box" />
              <span>盒柜</span>
            </router-link>
          </van-col>
          <van-col span="6" @click="showAddress()">
            <i class="my-icon icon-address" />
            <span>收货地</span>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Setting' }">
              <i class="my-icon icon-setting" />
              <span>设置</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Record' }">
              <i class="my-icon icon-order" />
              <span>交易记录</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <a :href="HomePage">
              <i class="my-icon icon-app" />
              <span>下载APP</span>
            </a>
          </van-col>
        </van-row>
      </div>

      <!--未绑定-->
      <Binder v-if="Agent.superior === 0 && Agent.role === 0" />
      <!--已绑定且有身份-->
      <div v-if="Agent.role > 0" class="item">
        <div class="tit van-hairline--bottom">
          代理
          <div class="more">
            <van-icon name="friends-o" />
            <em>{{ Agent.branch_num }}</em>
          </div>
        </div>
        <van-row>
          <!-- <van-col span="6">
            <i class="my-icon icon-agency" />
            <span>成为代理</span>
          </van-col> -->
          <van-col span="6">
            <span @click="sharePopShow = true">
              <i class="my-icon icon-invite" />
              <span>邀请</span>
            </span>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Member' }">
              <i class="my-icon icon-manage" />
              <span>管理</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Statistic' }">
              <i class="my-icon icon-stati" />
              <span>统计</span>
            </router-link>
          </van-col>
          <van-col span="6">
            <router-link :to="{ name: 'Codebox' }">
              <i class="my-icon icon-codebox" />
              <span>密码福袋</span>
            </router-link>
          </van-col>
        </van-row>
      </div>
      <!--/代理-->

      <!--item end-->
    </div>

    <van-popup
      v-model="sharePopShow"
      round
      cancel-text="取消"
      get-container="body"
      class="invite"
      closeable
      position="bottom"
    >
      <div class="items">
        <div class="item">
          <div class="tit">方式一</div>
          <div class="content">
            <div class="tip">
              发送链接分享给您的朋友，邀请他们加入。<br />成功注册后，自动成为您的下级用户。
            </div>
            <div class="end">
              <van-button
                type="info"
                round
                text="分享"
                @click="showShare(Agent.invite_code)"
              />
            </div>
          </div>
        </div>
        <div class="item inv-code">
          <div class="tit">方式二</div>
          <div class="content">
            <div class="tip">
              发送专属邀请码给您的好友，当他们绑定此邀请码时，将会成为您的下级用户。
            </div>
            <van-row gutter="10" class="end">
              <van-col span="18">
                <input disabled maxlength="12" v-model="Agent.invite_code" />
              </van-col>
              <van-col span="6">
                <van-button
                  size="small"
                  type="warning"
                  text="复制"
                  v-clipboard:copy="`bb#${Agent.invite_code}#`"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyError"
                />
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
      <!--分享面板-->
      <Share invoke="invite" title="魔力Go" />
    </van-popup>

    <!--我的地址-->
    <Address v-if="loadAddress" />
    <!--/我的地址-->
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import Binder from "@/components/Binder.vue";
import Share from "@/components/Share.vue";
import Address from "@/components/Address.vue";
import ServicesBtn from "@/components/ServicesBtn.vue";
export default {
  name: "My",
  components: {
    Binder,
    Share,
    Address,
    ServicesBtn,
  },
  data() {
    return {
      sharePopShow: false,
      loadAddress: false,
    };
  },
  computed: {
    User() {
      return this.$store.state.User;
    },
    Agent() {
      return this.$store.state.Agent;
    },
    NoteBadge() {
      return this.$store.state.NoteBadge;
    },
    HomePage() {
      return this.$store.state.HomePage;
    },
  },
  created() {},
  methods: {
    showAddress() {
      this.loadAddress = true;
      this.$nextTick(() => {
        Bus.$emit("showAddressOnbus");
      });
    },
    showShare(id) {
      Bus.$emit("showShareOnbus", id);
    },
    onCopy(e) {
      this.$toast.success("复制成功");
    },
    onCopyError(e) {
      this.$toast("复制失败\n请手动选择并复制");
    },
  },
};
</script>
