<template>
  <div v-if="goods" class="detail">
    <div class="detail-box">
      <!--pics-->
      <van-swipe
        class="pics"
        :autoplay="5000"
        @change="onChange"
        v-if="goods.item_imgs"
      >
        <van-swipe-item v-for="(image, i) in goods.item_imgs" :key="i">
          <img :src="image" />
        </van-swipe-item>
        <template #indicator>
          <div class="indicator">
            {{ active + 1 }} / {{ goods.item_imgs.length }}
          </div>
        </template>
      </van-swipe>

      <i class="share-icon" @click="showShare(goods.iid)" />

      <div class="info">
        <div
          class="favor"
          :class="{ lit: goods.favor === 1 }"
          @click="setFavor(goods.favor)"
        />
        <div class="price">￥{{ goods.price }}</div>
        <div class="title">{{ goods.title }}</div>
        <!-- <div class="tip">限时优惠 满500包邮</div> -->

        <div class="skus">
          <div class="sku-tit">
            型号：<em>{{ goods.skus[0].vname }}</em>
          </div>
          <!-- <div
            class="sku-item"
            :class="{ active: selectedSku.vid === sku.vid }"
            v-for="sku in goods.skus"
            :key="sku.vid"
            @click="setSku(sku)"
          >
            {{ sku.vname }}
          </div> -->
        </div>
      </div>

      <div class="desc">{{ goods.desc }}</div>

      <div class="content">
        <div
          class="img-container"
          v-for="(image, i) in goods.item_detail_imgs"
          :key="i"
        >
          <img :src="image" />
        </div>
      </div>
      <!--/content-->
    </div>

    <div class="paybar">
      <van-badge :content="CartBadge < 1 ? null : CartBadge" max="99">
        <router-link to="/cart">
          <i class="cart-icon-black" />
          <span>购物车</span>
        </router-link>
      </van-badge>
      <div class="btns">
        <van-button plain type="warning" @click="putInCart()">
          加入购物车
        </van-button>
        <van-button type="info" @click="putInCart(goods.iid)">
          立即购买
        </van-button>
      </div>
    </div>

    <!--回到顶部-->
    <transition name="fade">
      <van-button
        type="default"
        class="to-top-btn"
        icon="arrow-up"
        @click="toTop"
        v-show="showTotop"
      />
    </transition>
    <!--分享面板-->
    <Share
      v-if="goods"
      invoke="goods"
      :title="goods.title"
      :img="goods.pic_thumb"
    />
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import Share from "@/components/Share.vue";
export default {
  name: "Detail",
  components: {
    Share,
  },
  data() {
    return {
      active: 0,
      iid: null,
      goods: null,
      showTotop: false,
      selectedSku: null,
    };
  },
  computed: {
    CartBadge() {
      return this.$store.state.CartBadge;
    },
  },
  created() {
    this.iid = this.$route.params.id;
    this.getDetail();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setSku(sku) {
      this.selectedSku = sku;
      //this.goods.price = sku.price;
    },
    showShare(id) {
      Bus.$emit("showShareOnbus", id);
    },
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 300) {
        this.showTotop = true;
      } else {
        this.showTotop = false;
      }
    },
    putInCart(iid) {
      this.$ajax
        .post(
          "/user/cart/add/",
          {
            iid: this.iid,
            sku_id: this.selectedSku.sku_id,
            quantity: 1,
          },
          "loading"
        )
        .then((res) => {
          this.$store.commit("CartBadge", res.cart_num);
          //如果有iid，就是快捷购买模式，添加到购物车后跳转到购物车切选中该商品
          if (iid) {
            this.$router.push({ name: "Cart", params: { iids: [iid] } });
          } else {
            this.$toast.success("已加入购物车");
          }
        });
    },
    setFavor(int) {
      let keyurl = int === 0 ? "add" : "remove";
      this.$ajax
        .post(`/user/favor/${keyurl}/`, { iid: this.iid }, "loading")
        .then((res) => {
          this.goods = res.data;
          if (res.data.favor === 1) {
            this.$toast.success("已收藏");
          } else {
            this.$toast.fail("已取消收藏");
          }
        });
    },
    onChange(index) {
      this.active = index;
    },
    getDetail() {
      this.$ajax
        .post("/market/item/get/", { iid: this.iid }, "loading")
        .then((res) => {
          this.goods = res.data;
          this.setSku(res.data.skus[0]);
        });
    },
  },
};
</script>
