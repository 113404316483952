<template>
  <van-row class="code-boxs">
    <van-col v-for="item in items" :key="item.pos" span="8">
      <div class="round-box" @click="openbox(item)">
        <div
          class="gift-box"
          :class="{ isopen: item.username || item.open === 'opened' }"
        />
        <div class="opened-user">{{ item.username }}</div>
        <van-loading v-if="item.open === 'opening'" />
      </div>
    </van-col>
  </van-row>
</template>

<script>
import Bus from "@/api/bus.js";
export default {
  name: "",
  props: {
    oid: Number,
    bid: Number,
    takeAction: {
      type: Boolean,
      default: false,
    },
    mod: {
      type: String,
      default: "open", //view则为查看模式
    },
  },
  data() {
    return {
      total: 0,
      items: [],
      round: null,
      opening: false,
    };
  },
  watch: {
    //查看本轮进度用的
    takeAction(val) {
      if (val) this.getRound();
    },
  },
  created() {
    this.getRound();
  },
  mounted() {},
  methods: {
    getRound() {
      this.$ajax
        .post("/market/box/round/", {
          oid: this.oid,
          bid: this.bid,
        })
        .then((res) => {
          this.round = res.round;
          this.items = [];
          //生成数据
          for (let i = 1; i <= res.total; i++) {
            let box = { pos: i };
            this.items.push(box);
          }
          //已经打开的盒子
          if (res.data) {
            res.data.forEach((item) => {
              this.items.splice(item.pos - 1, 1, item);
            });
          }
        });
    },
    openbox(item) {
      if (this.mod !== "view" && !item.username) {
        this.$set(item, "open", "opening");
        this.$ajax
          .post("/user/cabinet/pick/", {
            oid: this.oid,
            round: this.round,
            pos: item.pos,
          })
          .then((res) => {
            this.$set(item, "open", "opened");
            setTimeout(() => {
              Bus.$emit("openBoxOnRound");
            }, 100);
          });
      }
    },
  },
};
</script>
