<template>
  <van-cell span="12" :class="{ isbox: item.type === 2 }">
    <router-link
      :to="
        item.type === 1
          ? { name: 'Detail', params: { id: item.iid } }
          : { name: 'BoxDetail', params: { id: item.bid } }
      "
      class="cover"
    >
      <!--商品-->
      <img v-if="item.type === 1" :src="item.pic" />
      <!--欧气福袋-->
      <van-swipe
        v-if="item.type === 2"
        :autoplay="3500"
        :show-indicators="false"
      >
        <van-swipe-item v-for="(img, i) in item.item_imgs" :key="i">
          <img :src="img" />
        </van-swipe-item>
      </van-swipe>
      <!--/欧气福袋图片滚动-->
      <div class="title van-multi-ellipsis--l2">{{ item.title }}</div>
      <div class="price">￥{{ item.price }}</div>
      <div v-if="item.type === 1" class="favor-num">
        {{ item.favor_num }}人想要
      </div>
      <div v-if="item.type === 2" class="favor-num">
        {{ item.open_num }}人已购买
      </div>

      <span v-if="item.badge === 1" class="badge">热门</span>
      <span v-if="item.badge === 2" class="badge new">新品</span>
    </router-link>

    <div
      v-if="page === 'catesub'"
      class="to-cart"
      @click="parentputInCart($event, item)"
    />

    <i
      v-if="page === 'fav'"
      class="favor lit"
      @click="parentFavToggle(item.iid)"
    />
  </van-cell>
</template>

<script>
export default {
  name: "GoodsItem",
  props: {
    item: Object,
    page: String,
  },
  data() {
    return {};
  },
  methods: {
    // parentputInCart(event, id) {
    //   this.$parent.$parent.putInCart(event, id);
    // },
    parentFavToggle(id) {
      this.$parent.favToggle(id);
    },
  },
};
</script>