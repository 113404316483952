<template>
  <van-swipe class="sda" :autoplay="3900">
    <van-swipe-item v-for="(ad, i) in ads" :key="i">
      <span @click="jump(ad)"><img :src="ad.pic" /></span>
    </van-swipe-item>
  </van-swipe>
</template>


<script>
export default {
  name: "Swipe",
  props: {
    ads: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    jump(ad) {
      if (ad.link_type === 0) return false;
      if (ad.link_type === 4) {
        location.href = ad.link_url;
        return false;
      }
      //0：nothing；1：欧气福袋主页；2：商品；3：欧气福袋；4：外链；5：订单
      let routerData;
      switch (ad.link_type) {
        case 1:
          routerData = { name: "Box" };
          break;
        case 2:
          routerData = { name: "Detail", params: { id: ad.link_url * 1 } };
          break;
        case 3:
          routerData = { name: "BoxDetail", params: { id: ad.link_url * 1 } };
          break;
        case 5:
          routerData = { name: "OrderDetail", params: { oid: ad.link_url } };
          break;
      }
      this.$router.push(routerData);
    },
  },
};
</script>