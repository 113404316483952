<template>
  <iframe v-if="url" class="iframe-box" :src="url" />
</template>


<script>
export default {
  name: "StaticPage",
  props: {
    invoke: String,
  },
  data() {
    return {
      url: null,
    };
  },
  watch: {
    invoke: {
      handler(val) {
        let htmlpage;
        switch (val) {
          case "agreement":
            htmlpage = "service_agreement.html";
            break;
          case "policy":
            htmlpage = "privacy_policy.html";
            break;
          case "buyer":
            htmlpage = "buyer_tips.html";
            break;
          case "rule":
            htmlpage = "blind_box_rule.html";
            break;
        }
        this.url = this.$global.WebURL + "/html/" + htmlpage;
      },
      immediate: true,
    },
  },
  created() {},
};
</script>