<template>
  <div class="item inv-code">
    <van-row gutter="10">
      <van-col span="18">
        <input placeholder="填写邀请码" maxlength="32" v-model="invite" />
      </van-col>
      <van-col span="6">
        <van-button size="small" type="warning" @click="bind()">
          绑定
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>


<script>
export default {
  name: "Binder",
  props: {},
  data() {
    return {
      invite: "",
    };
  },
  methods: {
    bind() {
      if (!this.invite) {
        this.$toast("请填写正确的邀请码~");
        return false;
      }
      this.$dialog
        .confirm({
          title: "是否绑定邀请码？",
          message: `${this.invite}`,
        })
        .then(() => {
          this.$ajax
            .post(
              "/agent/agent/bind/",
              {
                invite_code: this.invite,
              },
              "loading"
            )
            .then((res) => {
              this.$toast.success("绑定成功");
              this.agent = res.data;
              this.$store.commit("User", res.data.user);
            });
        })
        .catch(() => {});
    },
  },
};
</script>