<template>
  <div class="cart">
    <van-pull-refresh v-model="pullLoading" @refresh="getInCart('loading')">
      <!--购物车为空-->
      <div v-if="!inCart || inCart.length < 1" class="no-good">
        <van-empty
          description="购物车是空的"
          :image="require('../../assets/img/cart/icon_cart_blank@2x.png')"
          image-size="60"
        >
          <van-button round type="info" class="bottom-button" to="/home">
            去逛逛
          </van-button>
        </van-empty>
      </div>
      <!--购物车不为空-->
      <div v-else class="cart-list has-tabbar has-tips">
        <!--如果有公告，父级div要加上class="has-tips"-->
        <div class="tips">
          <van-notice-bar left-icon="volume-o">
            <span>限时优惠 满500包邮</span>
          </van-notice-bar>
        </div>
        <van-checkbox-group
          v-model="selected"
          :checked-color="$global.checkedColor"
        >
          <van-swipe-cell
            v-for="item in inCart"
            :key="item.cart_id"
            :name="item.cart_id"
            :before-close="beforeClose"
          >
            <!--如果是编辑模式，让checkbox变成块级的，就点不到比它z-index小的链接-->
            <div class="goods-card" :class="{ 'edit-mod': editmod }">
              <div class="chose">
                <van-checkbox :name="item.cart_id" icon-size="16px" />
              </div>
              <div class="pic" @click="toDetail(item.iid)">
                <img :src="item.pic" />
              </div>
              <div class="info" @click="toDetail(item.iid)">
                <div class="title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div class="type">{{ item.prop_name }}：{{ item.vname }}</div>
                <div v-if="!editmod" class="price">￥{{ item.price }}</div>
              </div>
              <van-stepper
                v-model="item.quantity"
                :max="item.upper_num > 99 ? 99 : item.upper_num"
                min="1"
                button-size="24px"
                @change="quantityChange(item)"
                integer
              />
              <div v-if="editmod" class="delete">
                <van-icon name="delete-o" @click="takeout([item.cart_id])" />
              </div>
            </div>
            <template #right>
              <van-button
                square
                text="删除"
                type="danger"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
        </van-checkbox-group>

        <!--失效商品-->
        <van-cell-group v-if="invalid.length > 0">
          <van-swipe-cell
            v-for="item in invalid"
            :key="item.cart_id"
            :name="item.cart_id"
            :before-close="beforeClose"
          >
            <div class="goods-card invalid">
              <div class="chose">
                <van-checkbox :name="item.cart_id" disabled icon-size="16px" />
              </div>
              <div class="pic">
                <img :src="item.pic" />
              </div>
              <div class="info">
                <div class="title van-multi-ellipsis--l2">{{ item.title }}</div>
                <div class="type">{{ item.prop_name }}：{{ item.vname }}</div>
              </div>
              <div class="van-stepper">已失效</div>
              <div class="delete">
                <van-icon name="delete-o" @click="takeout([item.cart_id])" />
              </div>
            </div>
            <template #right>
              <van-button
                square
                text="删除"
                type="danger"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
        </van-cell-group>
        <!--失效商品-->
      </div>
    </van-pull-refresh>
    <div v-if="inCart && inCart.length > 0" class="end">
      <div class="opt">
        <van-checkbox
          v-model="allChecked"
          icon-size="16px"
          @click="onCheckAll"
          :checked-color="$global.checkedColor"
        >
          全选
        </van-checkbox>
        <van-button size="small" @click="editmod = !editmod">
          <span v-if="editmod">完成</span>
          <span v-else>编辑</span>
        </van-button>
      </div>
      <div v-if="editmod" class="del-mod">
        <button class="btn" :disabled="selected < 1" @click="takeout(selected)">
          删除
          <span v-if="selected.length > 0">({{ total }})</span>
        </button>
      </div>
      <div v-else>
        <button class="btn" @click="makePrepay()" :disabled="selected < 1">
          结算
          <span v-if="selected.length > 0">({{ total }})</span>
        </button>
        <div class="total-box">
          <div class="total">
            总计：<em>￥{{ amount }}</em>
          </div>
          <div class="muted">运费：￥0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsItem from "@/components/GoodsItem.vue";
export default {
  name: "Task",
  components: {
    GoodsItem,
  },
  data() {
    return {
      pullLoading: false, //下拉刷新
      editmod: false,
      selected: [],
      total: 0,
      amount: 0,
      allChecked: false,
      inCart: null,
      invalid: [],
      goods: null,
      loading: false,
      finished: false,
      quantityUpdating: null, //提交更新的对象，用于节流
    };
  },
  computed: {
    CartBadge() {
      return this.$store.state.CartBadge;
    },
  },
  created() {
    this.getInCart();
  },
  watch: {
    selected(val) {
      if (val.length < this.inCart.length) {
        this.allChecked = false;
      } else {
        this.allChecked = true;
      }
      this.calculated();
    },
  },
  methods: {
    calculated() {
      this.total = 0;
      this.amount = 0;
      if (this.selected && this.selected.length > 0) {
        this.selected.forEach((cid) => {
          this.inCart.forEach((item) => {
            if (item.cart_id === cid) {
              this.total += item.quantity;
              this.amount += item.price * item.quantity;
            }
          });
        });
      }
    },
    toDetail(cid) {
      if (!this.editmod) {
        this.$router.push({ name: "Detail", params: { id: cid } });
      }
    },
    makePrepay() {
      this.$store.commit("Preids", this.selected);
      this.$router.push("/prepay/cart"); //cart必须，用于prepay识别是商品还是盒柜
    },
    takeoutHandle(cids, instance) {
      let ids = cids.join();
      this.$ajax
        .post("/user/cart/remove/", { cart_ids: ids }, "loading")
        .then((res) => {
          if (res.data) {
            if (instance) instance.close();
          }
          //从购物车列表里删除
          this.inCart = this.inCart.filter(
            (item) => !cids.includes(item.cart_id)
          );
          //从失效列表里删除
          this.invalid = this.invalid.filter(
            (item) => !cids.includes(item.cart_id)
          );
          //一定先从列表里删除才从选中里删除
          this.removeSelected(cids);
          this.setCartBadge(res.cart_num);
        });
    },
    beforeClose({ name, position, instance }) {
      switch (position) {
        case "left":
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          this.takeout([name], instance);
          break;
      }
    },
    takeout(cids, instance) {
      let message =
        cids.length > 1 ? "确定要删除所选商品吗？" : "确定要删除该商品吗？";
      if (instance) {
        //如果是滑动删除的直接删除
        this.takeoutHandle(cids, instance);
      } else {
        //如果是点击删除的先确认
        this.$dialog
          .confirm({
            message: message,
          })
          .then(() => {
            this.takeoutHandle(cids);
          })
          .catch(() => {});
      }
    },
    onCheckAll(val) {
      if (this.allChecked) {
        //全选
        this.selected = [];
        this.inCart.forEach((item) => {
          this.selected.push(item.cart_id);
        });
      } else {
        this.selected = [];
      }
    },
    removeSelected(cids) {
      //从选中的商品中删除
      if (this.selected.length > 0) {
        cids.forEach((cid) => {
          let index = null;
          for (let i = 0; i < this.selected.length; i++) {
            if (this.selected[i] === cid) {
              index = i;
              this.selected.splice(i, 1);
              break;
            }
          }
          //为了能让watch监听到改变
          if (!index) {
            this.selected.splice();
          }
        });
      }
    },
    setCartBadge(num) {
      this.$store.commit("CartBadge", num);
    },
    quantityChange(item) {
      if (!item.quantity) {
        return false;
      } else if (item.quantity < 1) {
        this.$toast("至少要购买1件商品");
        return false;
      } else if (item.quantity > item.upper_num) {
        this.$toast(`本商品最多可购买${item.upper_num}件`);
        return false;
      } else if (item.quantity > 99) {
        this.$toast("最多可以购买99件商品");
        return false;
      }
      clearTimeout(this.quantityUpdating);
      this.quantityUpdating = setTimeout(() => {
        this.$ajax
          .post(
            "/user/cart/update/",
            {
              cart_id: item.cart_id,
              quantity: item.quantity,
            },
            "loading"
          )
          .then((res) => {
            this.calculated();
            this.setCartBadge(res.cart_num);
          });
      }, 500);
    },
    //下拉刷新的不需要显示Loading层
    getInCart(noNeedLoading) {
      let loading = noNeedLoading ? null : "loading";
      this.$ajax.post("/user/cart/index/", {}, loading).then((res) => {
        this.pullLoading = false;
        if (res.data) {
          this.inCart = [];
          //分到有效和失效的数组里
          res.data.forEach((item) => {
            if (item.status === 1) {
              this.inCart.push(item);
              this.selected.splice(); //为了能让watch监听到改变重新计算选中
            } else {
              this.invalid.push(item);
            }
          });
          //如果是再次购买的
          let iids = this.$route.params.iids;
          if (iids) {
            this.selected = [];
            iids.forEach((iid) => {
              for (let i = 0; i < this.inCart.length; i++) {
                const goods = this.inCart[i];
                if (iid === goods.iid) {
                  this.selected.push(goods.cart_id);
                  break;
                }
              }
            });
          }
        }
        this.setCartBadge(res.cart_num);
        //cart_num	int	购物车数量；quantity_chg	int	购物车是否发生变化
      });
    },
  },
};
</script>
