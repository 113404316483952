<template>
  <div v-if="cats" class="home">
    <div class="home-hd">
      <div class="logo">
        <img :src="$global.logo" />
      </div>
      <div class="search space">
        <!-- <van-icon name="search" />
        <span>这里是默认搜索</span> -->
      </div>
      <van-badge class="more" :dot="NoteBadge > 0">
        <router-link :to="{ name: 'Note' }">
          <van-icon class="icon-msg" />
        </router-link>
      </van-badge>
    </div>
    <!--分类-->
    <!--图片width46，加边框就是48，间距16，-->
    <div class="home-cats">
      <ul>
        <li class="home-cat" v-for="cat in cats" :key="cat.cid">
          <router-link :to="{ name: 'Category', params: { name: cat.name } }">
            <img :src="cat.pic" />
            <span>{{ cat.name }}</span>
          </router-link>
          <!-- <router-link :to="{ name: 'Catesub', params: { id: cat.cid } }">
            <img :src="cat.pic" />
            <span>{{ cat.name }}</span>
          </router-link> -->
        </li>
        <li class="home-cat">
          <router-link :to="{ name: 'Category' }">
            <img :src="require('../../assets/img/nav_category_1_5@2x.png')" />
            <span>全部</span>
          </router-link>
        </li>
      </ul>
    </div>

    <!--轮播-->
    <Swipe :ads="ads" />

    <!--内容-->
    <div class="home-main" v-if="tabs">
      <van-tabs class="tabs" type="card" @change="onChange">
        <van-tab
          v-for="tab in tabs"
          :title="tab.tab_name"
          :key="tab.tab_id"
          :name="tab.tab_id"
        >
        </van-tab>
        <van-list
          v-model="loading"
          :finished="finished"
          @load="getGoods"
          class="goods-list"
        >
          <GoodsItem :item="item" v-for="(item, i) in goods" :key="i" />
        </van-list>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import Swipe from "@/components/Swipe.vue";
import GoodsItem from "@/components/GoodsItem.vue";
export default {
  name: "Shop",
  components: {
    Swipe,
    GoodsItem,
  },
  data() {
    return {
      cats: null,
      ads: [],
      tabs: null,
      goods: null,
      page: 0,
      activeTabID: null,
      loading: false,
      finished: false,
    };
  },
  computed: {
    NoteBadge() {
      return this.$store.state.NoteBadge;
    },
  },
  created() {
    this.getHome();
  },
  mounted() {},
  methods: {
    onChange(tabid) {
      this.page = 0;
      this.finished = false;
      this.activeTabID = tabid;
      this.getGoods();
    },
    getHome() {
      this.$ajax.post("/market/onsale/index/", {}, "loading").then((res) => {
        this.ads = res.ads;
        this.cats = res.cats;
        this.tabs = res.tabs;
        this.activeTabID = this.tabs[0].tab_id;
      });
    },
    getGoods() {
      this.$ajax
        .post("/market/onsale/tab/", {
          tab_id: this.activeTabID,
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          if (this.page === 0) {
            this.goods = res.data;
          } else {
            if (res.data) {
              this.goods = [...this.goods, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
        });
    },
  },
};
</script>
