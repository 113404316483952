import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Coin: 0,
    CartBadge: null,
    NoteBadge: null,
    BoxBadge: null,
    TaskBadge: null,
    System: null,
    Agent: null,
    User: null,
    HomePage: null,
    Service: null,
    Preids: null,
    Carefree: null,
  },
  mutations: {
    Coin(state, num) {
      state.Coin = num;
    },
    CartBadge(state, num) {
      state.CartBadge = num;
    },
    NoteBadge(state, num) {
      state.NoteBadge = num;
    },
    BoxBadge(state, num) {
      state.BoxBadge = num;
    },
    TaskBadge(state, num) {
      state.TaskBadge = num;
    },
    Agent(state, obj) {
      state.Agent = obj;
    },
    User(state, obj) {
      state.User = obj;
    },
    HomePage(state, Str) {
      state.HomePage = Str;
    },
    System(state, obj) {
      state.System = obj;
    },
    Service(state, obj) {
      state.Service = obj;
    },
    Preids(state, arr) {
      state.Preids = arr;
    },
    Carefree(state, arr) {
      state.Carefree = arr;
    },
  },
  //dispatch（actions）是异步，commit（mutations）是同步
  actions: {
    CoinCommit(context, num) {
      context.commit("Coin", num);
    },
  },
  modules: {},
  plugins: [persistedState()],
});
