<template>
  <div>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
    <van-overlay
      :show="wxOverlay"
      class-name="wechat-overlay"
      @click="wxOverlay = false"
    >
      <div class="wechat-mask-content">
        <van-icon name="share-o" />
        <p>请点击右上角的按钮</p>
        <p>分享给好友或分享到朋友圈</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import Bus from "@/api/bus.js";
export default {
  name: "Share",
  props: {
    invoke: String,
    title: String,
    img: { type: String, default: "" },
  },
  data() {
    return {
      key: "",
      wxOverlay: false,
      showShare: false,
      imgUrl: this.$global.WebURL + "/img/favicon.png",
      desc: "", // 分享描述
      link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      options: [
        // { name: "微信", icon: "wechat" },
        { name: "复制链接", icon: "link" },
      ],
    };
  },
  computed: {
    Agent() {
      return this.$store.state.Agent;
    },
  },
  created() {
    switch (this.invoke) {
      case "box":
        this.desc = "我在魔力Go抽限量款好物，快来和我一起玩吧！";
        break;
      case "goods":
        this.desc = "我在魔力Go发现了一件不错的东西，赶快来看看吧！";
        break;
      case "invite":
        this.desc = "我在魔力Go发现了限量好物，快来和我一起玩！";
        break;
    }
    this.imgUrl = this.img || this.imgUrl;
    this.initWxShare();
  },
  mounted() {
    Bus.$off("showShareOnbus");
    Bus.$on("showShareOnbus", (key) => {
      this.showShare = true;
      this.key = key;
    });
  },
  methods: {
    initWxShare() {
      let that = this;
      this.$ajax
        .post("account/wechat/jsconfig", { url: this.link })
        .then((res) => {
          //遮罩
          let dk = res.config;
          wx.config({
            debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
            appId: dk.app_id, // 必填，公众号的唯一标识
            timestamp: dk.timestamp, // 必填，生成签名的时间戳
            nonceStr: dk.nonce_str, // 必填，生成签名的随机串
            signature: dk.signature, // 必填，签名
            jsApiList: [
              "checkJsApi",
              "updateAppMessageShareData",
              "updateTimelineShareData",
            ], // 必填，需要使用的JS接口列表
          });

          //需在用户可能点击分享按钮前就先调用
          wx.ready(() => {
            //10月10日起新提交发布的版本，不再支持分享回调参数 success 、fail 、complete，即用户从小程序/小游戏中分享消息给好友时，开发者将无法获知用户是否分享完成，也无法在分享后立即获得分享成功后的回调参数shareTicket。
            let shareObj = {
              title: this.title, // 分享标题
              desc: this.desc, // 分享描述
              link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: this.imgUrl, // 分享图标
            };
            wx.updateAppMessageShareData(shareObj); //分享给好友
            wx.updateTimelineShareData(shareObj); //分享给朋友圈
          });
        });
    },
    onSelect(option) {
      this.showShare = false;
      //如果是复制邀请码，就要加上邀请码属性
      // if (this.invoke === "invite") {
      //   let mark = this.link.indexOf("?") < 0 ? "?" : "&";
      //   this.link += `${mark}invite=${this.key}`;
      //   this.link = this.link.replace("my", "");
      // }

      //如果是代理
      if (this.Agent.invite_code) {
        //拼接协议和主机名 https://host:80
        this.link = `${location.protocol}//${location.host}/`;
        //加上hash，也就是#号后，hash里可能有问号，只取问号前的内容
        this.link += `${location.hash.split("?")[0]}`;
        //去掉从”我的“页分享出来的路径里的my
        this.link = this.link.replace("my", "");
        //拼接上自己的邀请码
        this.link += `?invite=${this.Agent.invite_code}`;
      }
      // 读取剪贴板
      // navigator.clipboard.readText().then((clipText) => {
      //   this.link = clipText;
      //   console.log("clipText", clipText);
      //   console.log("this.link", this.link);
      // });

      if (option.name === "复制链接") {
        this.copyURL(this.link);
      } else if (option.name === "微信") {
        //微信已暂时去掉
        this.wxOverlay = true;
        console.log({
          title: this.title, // 分享标题
          desc: this.desc, // 分享描述
          link: this.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.imgUrl,
        });
        if (typeof WeixinJSBridge === "undefined") {
          this.copyURL(url);
        } else {
          this.wxShare();
        }
      }
    },
    copyURL(url) {
      this.$copyText(url).then(
        (e) => {
          this.$toast("已复制，快去分享给好友吧！");
        },
        (e) => {
          this.$toast("复制失败\n请手动选择并复制");
        }
      );
    },
  },
};
</script>
