//识别正式或测试环境 formal or test
const ment = location.origin.indexOf("h5.hihis") !== -1 ? "test" : "formal";
//公众号appid
//const appid = 'wxee56b108097b9929';
/* ---------- 分割线 ---------- */
let APIDomain, WebURL;
if (ment === "test") {
  APIDomain = "sl.hihisoft.cn";
  WebURL = "https://h5.hihisoft.cn";
} else {
  APIDomain = "g.huanjumoli.cn";
  WebURL = "https://app.huanjumoli.cn";
}
//微信登录地址
const wxLoginUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx128869ca58cab769&response_type=code&scope=snsapi_userinfo&redirect_uri=https%3A%2F%2F${APIDomain}%2Faccount%2Fsite%2Fmp%2F%3F`;
/* ---------- 分割线 ---------- */
//默认设置
const logo = require("../assets/img/logo.png");
const nodata = require("../assets/img/nodata.png");
const checkedColor = "#fd5d39"; //checkbox 默认颜色
const attrs = [
  {
    value: 1,
    label: "型号",
  },
  {
    value: 2,
    label: "颜色",
  },
  {
    value: 3,
    label: "重量",
  },
];
export default {
  ment,
  APIDomain,
  logo,
  nodata,
  checkedColor,
  WebURL,
  wxLoginUrl,
  attrs,
  stamp10() {
    return parseInt(new Date().valueOf() / 1000);
  },
};
