import Vue from "vue";
import VueRouter from "vue-router";
import Shop from "../views/Goods/Shop.vue";
import Download from "../views/Download.vue";
import Box from "../views/Box/Box.vue";
import BoxDetail from "../views/Box/BoxDetail.vue";
import Task from "../views/Task.vue";
import Cart from "../views/Goods/Cart.vue";
import Detail from "../views/Goods/Detail.vue";
import My from "../views/My/My.vue";

Vue.use(VueRouter);

//解决报错：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx".
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/box",
  },
  {
    path: "/shop",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
  },
  //分类
  {
    path: "/category",
    name: "Category",
    component: () =>
      import(/* webpackChunkName: "Category" */ "../views/Goods/Category.vue"),
  },
  {
    path: "/category/:id",
    name: "Catesub",
    component: () =>
      import(/* webpackChunkName: "Catesub" */ "../views/Goods/Catesub.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
  },
  //欧气福袋
  {
    path: "/box",
    name: "Box",
    component: Box,
  },
  {
    path: "/box/detail/:id/:mod?",
    name: "BoxDetail",
    component: BoxDetail,
  },
  {
    path: "/box/draw",
    name: "Draw",
    component: () =>
      import(/* webpackChunkName: "Draw" */ "../views/Box/Draw.vue"),
  },
  {
    path: "/box/cabinet",
    name: "Cabinet",
    component: () =>
      import(/* webpackChunkName: "Cabinet" */ "../views/Box/Cabinet.vue"),
  },
  {
    path: "/box/record",
    name: "Record",
    component: () =>
      import(/* webpackChunkName: "Record" */ "../views/Box/Record.vue"),
  },
  //任务/每日签到
  {
    path: "/task",
    name: "Task",
    component: Task,
  },
  //订单
  {
    path: "/goods/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "Orders" */ "../views/Goods/Orders.vue"),
  },
  {
    path: "/order/:oid",
    name: "OrderDetail",
    component: () =>
      import(
        /* webpackChunkName: "OrderDetail" */ "../views/Goods/OrderDetail.vue"
      ),
  },
  {
    path: "/trace/:oid",
    name: "Trace",
    component: () =>
      import(/* webpackChunkName: "Trace" */ "../views/Goods/Trace.vue"),
  },
  //购物车
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  //购物车（用于二级页面，不显示Tabbar）
  {
    path: "/shopping/cart",
    name: "Cart2",
    component: Cart,
  },
  {
    path: "/prepay/:from",
    name: "Prepay",
    component: () =>
      import(/* webpackChunkName: "Prepay" */ "../views/Pay/Prepay.vue"),
  },
  {
    path: "/pay",
    name: "Pay",
    component: () =>
      import(/* webpackChunkName: "Pay" */ "../views/Pay/Pay.vue"),
  },
  //我的
  {
    path: "/my",
    name: "My",
    component: My,
  },
  {
    path: "/my/note",
    name: "Note",
    component: () =>
      import(/* webpackChunkName: "Note" */ "../views/My/Note.vue"),
  },
  {
    path: "/my/setting",
    name: "Setting",
    component: () =>
      import(/* webpackChunkName: "Setting" */ "../views/My/Setting.vue"),
  },
  {
    path: "/my/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/My/Profile.vue"),
  },
  {
    path: "/my/favorite",
    name: "Favorite",
    component: () =>
      import(/* webpackChunkName: "Favorite" */ "../views/My/Favorite.vue"),
  },
  {
    path: "/statistic",
    name: "Statistic",
    component: () =>
      import(
        /* webpackChunkName: "Statistic" */ "../views/Agent/Statistic.vue"
      ),
  },
  {
    path: "/codebox",
    name: "Codebox",
    component: () =>
      import(/* webpackChunkName: "Codebox" */ "../views/Agent/Codebox.vue"),
  },
  {
    path: "/withdrawal/bills",
    name: "Bills",
    component: () =>
      import(/* webpackChunkName: "Bills" */ "../views/Pay/Bills.vue"),
  },
  {
    path: "/member",
    name: "Member",
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/Agent/Member.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
