<template>
  <van-cell span="12" @click="parentOpenGoods(item)">
    <div class="cover">
      <img :src="item.pic" />
    </div>
    <div class="title van-multi-ellipsis--l2">{{ item.title }}</div>
    <div class="price price4box">参考价 ￥{{ item.price }}</div>
    <span class="lv-badge" :class="'itevel-' + item.rarity">{{
      item.rarity | grade("款")
    }}</span>
  </van-cell>
</template>

<script>
export default {
  name: "GoodsItem",
  props: {
    item: Object,
    page: String,
  },
  data() {
    return {};
  },
  methods: {
    parentOpenGoods(item) {
      this.$parent.$parent.$parent.openGoods(item);
    },
  },
};
</script>