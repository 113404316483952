<template>
  <van-tabs
    class="tabs mbox-tabs"
    type="card"
    v-model="active"
    @change="purcChange"
  >
    <van-tab
      v-for="tab in tabs"
      :key="tab"
      :title="tab | grade('款')"
      :name="tab"
    />
    <van-empty v-if="!records || records.length < 1" description="暂无记录" />
    <van-list
      v-model="loading"
      :finished="finished"
      @load="purcLoad"
      class="purc-list"
    >
      <van-cell
        v-for="(record, i) in records"
        :key="i"
        :title="record.username | hideword"
        :label="record.created_at | formatDate"
        center
      >
        <template #default>
          <span class="lv-badge" :class="'itevel-' + record.rarity"
            >{{ record.rarity | grade }}款</span
          >
          <div class="pic"><img :src="record.pic" /></div>
        </template>
      </van-cell>
    </van-list>
  </van-tabs>
</template>


<script>
export default {
  name: "",
  props: {
    bid: Number,
  },
  data() {
    return {
      page: 0,
      records: null,
      tabs: [-1, 0, 1, 2, 3],
      active: -1,
      loading: false,
      finished: false,
    };
  },
  created() {},
  methods: {
    purcChange(rarity) {
      if (rarity) this.active = rarity;
      this.page = 0;
      this.finished = false;
      this.purcLoad();
    },
    purcLoad() {
      this.$ajax
        .post("/market/box/cabinets/", {
          bid: this.bid,
          rarity: this.active,
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          if (this.page === 0) {
            this.records = res.data;
          } else {
            if (res.data) {
              this.records = [...this.records, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
        });
    },
  },
};
</script>