<template>
  <van-tabbar
    v-if="['Box', 'Shop', 'Task', 'Cart', 'My'].includes($route.name)"
    :border="false"
    route
  >
    <van-tabbar-item
      v-for="tabbar in tabbars"
      :to="tabbar.to"
      :key="tabbar.tabid"
      :badge="getBadge(tabbar.tabid)"
    >
      <span>{{ tabbar.text }}</span>
      <template #icon="props">
        <img :src="props.active ? tabbar.active : tabbar.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>


<script>
export default {
  name: "Tabbar",
  data() {
    return {
      tabbars: [
        {
          tabid: 1,
          text: "福袋",
          to: { name: "Box" },
          active: this.geticon("box", "active"),
          inactive: this.geticon("box"),
          badgeID: 3,
        },
        {
          tabid: 2,
          text: "购物",
          to: { name: "Shop" },
          active: this.geticon("shopping", "active"),
          inactive: this.geticon("shopping"),
        },
        {
          tabid: 3,
          text: "任务成就",
          to: { name: "Task" },
          active: this.geticon("task", "active"),
          inactive: this.geticon("task"),
          badgeID: 4,
        },
        {
          tabid: 4,
          text: "购物车",
          to: { name: "Cart" },
          active: this.geticon("cart", "active"),
          inactive: this.geticon("cart"),
          badgeID: 1,
        },
        {
          tabid: 5,
          text: "我的",
          to: { name: "My" },
          active: this.geticon("my", "active"),
          inactive: this.geticon("my"),
          badgeID: 2,
        },
      ],
    };
  },
  computed: {
    CartBadge() {
      return this.$store.state.CartBadge;
    },
    BoxBadge() {
      return this.$store.state.BoxBadge;
    },
    TaskBadge() {
      return this.$store.state.TaskBadge;
    },
    NoteBadge() {
      return this.$store.state.NoteBadge;
    },
  },
  created() {},
  mounted() {},
  methods: {
    getBadge(tabid) {
      //tabbar 1.购物, 2.福袋, 3.任务, 4.购物, 5.我的
      let badge = null;
      switch (tabid) {
        case 2:
          //badge = this.BoxBadge;
          badge = null; //bug73#不需要显示
          break;
        case 3:
          badge = this.TaskBadge;
          break;
        case 4:
          badge = this.CartBadge;
          break;
        case 5:
          badge = this.NoteBadge;
          break;
      }
      return badge > 0 ? badge : null;
    },
    geticon(icon, active) {
      let type = active ? icon + "_on" : icon + "_off";
      return require("../assets/img/tabbar/icon_navbar_" + type + "@2x.png");
    },
  },
};
</script>