import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueClipboard from "vue-clipboard2";

//全局变量
import global from "./api/global";
Vue.prototype.$global = global;

//cookie
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

//ajax
import ajax from "./api/ajax";
Vue.prototype.$ajax = ajax;

//过滤器
import filters from "./api/filter";
for (let key in filters) {
  Vue.filter(key, filters[key]);
}

//VueClipboard
Vue.use(VueClipboard);

//Vant
import {
  Button,
  Image as VanImage,
  Cell,
  CellGroup,
  Row,
  Col,
  Icon,
  Tag,
  Popup,
  Popover,
  Toast,
  List,
  SwipeCell,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Loading,
  Dialog,
  Tabbar,
  TabbarItem,
  PullRefresh,
  Calendar,
  Sticky,
  PasswordInput,
  NumberKeyboard,
  Empty,
  Field,
  Form,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Uploader,
  Skeleton,
  Badge,
  Area,
  Stepper,
  NoticeBar,
  Picker,
  DatetimePicker,
  ShareSheet,
  AddressEdit,
  CountDown,
  CouponCell,
  CouponList,
  IndexBar,
  IndexAnchor,
  Overlay,
} from "vant";
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Cell).use(CellGroup);
Vue.use(Row).use(Col);
Vue.use(Icon);
Vue.use(Tag);
Vue.use(Popup);
Vue.use(Popover);
Vue.use(Toast);
Vue.use(List);
Vue.use(SwipeCell);
Vue.use(Swipe).use(SwipeItem);
Vue.use(Tabs).use(Tab);
Vue.use(Loading);
Vue.use(Dialog);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(PullRefresh);
Vue.use(Calendar);
Vue.use(Sticky);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
Vue.use(Empty);
Vue.use(Field);
Vue.use(Form);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Uploader);
Vue.use(Skeleton);
Vue.use(Badge);
Vue.use(Area);
Vue.use(Stepper);
Vue.use(NoticeBar);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(ShareSheet);
Vue.use(AddressEdit);
Vue.use(CountDown);
Vue.use(CouponCell);
Vue.use(CouponList);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Overlay);

Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   if (store.state.Logged || to.meta.noNeedLogin) {
//     next();
//   } else {
//     next({
//       name: 'Login',
//       params: { redirect: to.fullPath },
//     });
//   }
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
