<template>
  <div>
    <van-popup
      v-model="showAddress"
      round
      get-container="body"
      closeable
      :style="{ height: '90%' }"
      position="bottom"
    >
      <div class="address">
        <!--新增收货地址按钮-->
        <span v-if="lists.length < 1" @click="editAddress()" class="no-addr">
          <i class="add-icon-dashed" />
          添加地址
        </span>

        <div
          class="cell"
          v-for="address in lists"
          :key="address.address_id"
          :data-id="address.address_id"
          :class="{
            active: mod === 'choice' && active === address.address_id,
          }"
        >
          <div class="content van-hairline--bottom" @click="selected(address)">
            <div class="forSelect">
              <div class="addr van-ellipsis">{{ address.addr }}</div>
              <span class="people">{{ address.contact_name }} </span>
              <span class="mobile">{{ address.mobile }} </span>
            </div>
          </div>

          <van-icon name="edit" @click="editAddress(address)" />
          <van-icon
            name="delete-o"
            @click="removeAddress(address.address_id)"
          />

          <div class="set">
            <van-button
              :icon="address.get_def === 1 ? 'checked' : 'circle'"
              :class="{ checked: address.get_def === 1 }"
              :disabled="address.get_def === 1"
              @click="setDefault(address)"
            >
              设为默认地址
            </van-button>
          </div>

          <div v-if="address.get_def" class="isdef">默认地址</div>
        </div>

        <!---->
        <div class="end-btn-box">
          <van-button round block type="warning" @click="editAddress()">
            <i class="add-icon-dashed" />
            <span>新增地址</span>
          </van-button>
        </div>
      </div>
    </van-popup>

    <!--新建/编辑-->
    <van-popup
      v-model="showEdit"
      round
      get-container="body"
      closeable
      position="bottom"
      class="edit-addr"
    >
      <EditAddr :addrProp="addrProp" />
    </van-popup>
  </div>
</template>

<script>
import Bus from "@/api/bus.js";
import EditAddr from "@/components/EditAddr.vue";
export default {
  name: "Address",
  components: {
    EditAddr,
  },
  props: {
    mod: {
      type: String,
      default() {
        return "view";
      },
    },
  },
  data() {
    return {
      showAddress: false,
      showEdit: false,
      lists: [],
      active: 0,
      addrProp: null,
    };
  },
  created() {
    this.getAddresses();
  },
  mounted() {
    Bus.$off("showAddressOnbus");
    Bus.$on("showAddressOnbus", (item) => {
      this.showAddress = true;
      //可以显示选中状态
      if (this.mod === "choice") {
        if (item) this.active = item.address_id;
        else this.editAddress();
      }
    });
    Bus.$off("addrUpdateOnBus");
    Bus.$on("addrUpdateOnBus", (addr) => {
      this.update(addr);
    });
  },
  methods: {
    update(item) {
      let inLists = false;
      for (let i = 0; i < this.lists.length; i++) {
        const address = this.lists[i];
        if (item.address_id === address.address_id) {
          inLists = true;
          this.lists.splice(i, 1, item);
          break;
        }
      }
      //如果不在地址列表里，说明是新增的
      if (!inLists) this.lists.push(item);
      this.showEdit = false;
      //如果新建的地址是默认地址，且有多个地址，就把其他地址的默认状态取消
      if (item.get_def === 1 && this.lists.length > 1) {
        for (let i = 0; i < this.lists.length; i++) {
          const aAddr = this.lists[i];
          if (aAddr.address_id !== item.address_id) aAddr.get_def = 0;
        }
      }
      //如果只有一个，且是在选择模式下，默认选中
      if (this.mod === "choice") {
        this.active = item.address_id;
      }
    },
    selected(item) {
      if (this.mod === "choice") {
        this.active = item.address_id;
        Bus.$emit("setAddressOnbus", item);
        this.showAddress = false;
      }
    },
    editAddress(addr) {
      this.addrProp = addr;
      this.showEdit = true;
    },
    removeAddress(id) {
      this.$dialog
        .confirm({
          message: "确定要删除该地址吗？",
        })
        .then(() => {
          let data = {
            address_id: id,
          };
          this.$ajax.post(`/user/address/remove/`, data, "loading").then(() => {
            for (let i = 0; i < this.lists.length; i++) {
              let item = this.lists[i];
              if (item.address_id === id) {
                this.lists.splice(i, 1);
                break;
              }
            }
            if (this.mod === "choice") Bus.$emit("reloadPrepayOnbus");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    setDefault(addr) {
      let data = addr;
      data.token = 1;
      data.get_def = 1;
      this.$ajax.post(`/user/address/update/`, data, "loading").then(() => {
        this.lists.forEach((item) => {
          if (addr.address_id === item.address_id) {
            item.get_def = 1;
          } else {
            item.get_def = 0;
          }
        });
      });
    },
    getAddresses() {
      this.$ajax.post("/user/address/index/", {}, "loading").then((res) => {
        this.lists = res.data ? res.data : [];
      });
    },
  },
};
</script>
