<template>
  <div class="download">
    <div class="btns">
      <van-button @click="jump('android')" text="Android下载" />
      <van-button @click="jump('ios')" class="ios-btn" text="iOS下载" />
    </div>

    <van-overlay
      :show="wxOverlay"
      class-name="wechat-overlay"
      @click="wxOverlay = false"
    >
      <div class="wechat-mask-content">
        <van-icon name="share-o" />
        <p>如果没有开始下载，请点击右上角的按钮</p>
        <p>选择“在浏览器中打开”再进行下载</p>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      wxOverlay: false,
      apkURL: "https://dl.huanjumoli.cn/android/app/app_106.apk",
      iosURL: "https://apps.apple.com/cn/app/%E9%AD%94%E5%8A%9Bgo/id1590501158",
    };
  },
  created() {},
  methods: {
    jump(os) {
      //this.wxOverlay = true;
      //识别微信
      var sUserAgent = navigator.userAgent.toLowerCase();
      var isWechat = sUserAgent.match(/micromessenger/i) == "micromessenger";
      if (!isWechat) this.wxOverlay = false;
      location.href = os === "ios" ? this.iosURL : this.apkURL;
    },
  },
};
</script>
