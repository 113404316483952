import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
import global from "./global";

//axios, 用在main里的
// Vue.prototype.$axios = axios;
// Vue.prototype.$qs = qs;
//让axios带cookies
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;
//后面不要带斜杠
axios.defaults.baseURL = "//" + global.APIDomain;
// 创建axios实例
let instance = axios.create();

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  (res) => (res.data.ret === 0 ? Promise.resolve(res) : Promise.reject(res)),
  // 请求失败
  (error) => {
    console.log(error);
    Toast(error);
  }
);

export default {
  instance,
  post: (url, params, extra) => {
    //extra目前有3种：1.默认loading标识需要显示loading；2.upload是上传不需要序列化data；3.bind是静默绑定不需要提示错误
    return new Promise((resolve, reject) => {
      let data = params;
      //如果有token这个属性，或是是上传图片，说明需要access_token
      if (data.donotNeedToken) delete data.donotNeedToken;
      //属性token已经没有用了
      else data.access_token = localStorage.getItem("token"); //自动加上token
      //是否要显示Loading
      if (extra && extra !== "bind") {
        let toastParams = {
          forbidClick: true,
          duration: 0,
        };
        //测试环境加上正在请求的url
        if (global.ment === "test") toastParams.message = url;
        Toast.loading(toastParams);
      }
      //上传图片不需要序列化
      if (extra !== "upload") data = qs.stringify(data);
      //开始请求
      axios
        .post(url, data)
        .then((res) => {
          let ret = res.data.ret;
          if (ret === 0) {
            resolve(res.data);
            if (extra) {
              Toast.clear();
            }
          } else if (res.data.message.indexOf("token") > -1) {
            //101是token没有
            if (global.ment === "test") {
              Toast(`${url}: ${res.data.ret}: ${res.data.message}`);
            } else {
              Toast(res.data.message);
            }
            return false;
          } else if ([104, 105].includes(ret)) {
            //101：没有token
            if (global.ment === "test") {
              Toast(`${url}: ${res.data.ret}: ${res.data.message}`);
            } else {
              Toast(res.data.message);
            }
            return false;
          } else if (ret === 1005) {
            //1005：异地登录
            if (global.ment === "test") {
              Toast(`${url}: ${res.data.ret}: ${res.data.message}`);
            } else {
              Toast(res.data.message);
            }
            setTimeout(() => {
              document.addEventListener(
                "WeixinJSBridgeReady",
                function () {
                  WeixinJSBridge.call("closeWindow");
                },
                false
              );
              WeixinJSBridge.call("closeWindow");
            }, 2000);
            return false;
          } else if (ret === 1008) {
            Toast("该商品已下架");
          } else if (ret === 1000) {
            Toast("商品库存不足");
          } else if (ret === 1010) {
            Toast("购物车数量达到上线");
          } else {
            if (extra !== "bind") {
              Toast(res.data.message);
            } else {
              Toast.clear();
            }
            reject(res.data);
          }
        })
        .catch((error) => {
          if (error && error.stack.indexOf("timeout") > -1) {
            Toast("网络不稳定，连接超时");
          } else {
            Toast(error.message);
          }
          reject(error);
        });
    });
  },
};
