<template>
  <van-button plain type="warning" @click.stop="contactService()">
    联系客服
  </van-button>
</template>

<script>
export default {
  name: "ServiceBtn",
  computed: {
    Service() {
      return this.$store.state.Service;
    },
  },
  methods: {
    contactService() {
      this.$dialog
        .confirm({
          title: "联系客服微信号",
          message: `<i class='wechat-icon-square'></i>${this.Service.wechat}`,
          confirmButtonText: "复制",
        })
        .then(() => {
          this.$copyText(this.Service.wechat).then(
            (e) => {
              this.$toast("已复制！");
            },
            (e) => {
              this.$toast("复制失败！\n请手动选择并复制");
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
