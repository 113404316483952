<template>
  <div class="box-swipe">
    <van-swipe :show-indicators="false" @change="onChange">
      <van-swipe-item v-for="(item, i) in items" :key="i">
        <img :src="item.pic" />
      </van-swipe-item>
    </van-swipe>
    <div class="indicator">{{ current + 1 }}/{{ items.length }}</div>
  </div>
</template>


<script>
export default {
  name: "BoxSwipe",
  props: {
    items: Array,
  },
  data() {
    return {
      current: 0,
    };
  },
  created() {},
  methods: {
    onChange(index) {
      this.current = index;
      this.$parent.swipeActive = this.items[index];
    },
  },
};
</script>