<template>
  <div v-if="gotSystem" id="app" class="wrap">
    <router-view v-if="isAlive" />
    <div v-if="showBeginner" class="beginner-alert" @touchmove.prevent>
      <div class="beginner-box">
        <img :src="beginnerAlert.pic" />
        <router-link
          :to="{ name: 'BoxDetail', params: { id: beginnerAlert.link_id } }"
          @click.native="showBeginner = false"
          class="beginner-btn"
          >0.1元 试试看</router-link
        >
        <van-icon name="cross" class="close" @click="showBeginner = false" />
      </div>
    </div>
    <Tabbar />
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar.vue";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    Tabbar,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      gotSystem: false,
      isAlive: true,
      uid: null,
      otoken: null,
      logged: false,
      invite: null,
      dlpageURL: "https://app.huanjumoli.cn/down/download.html",
      showBeginner: false,
      beginnerAlert: null,
    };
  },
  computed: {
    User() {
      return this.$store.state.User;
    },
  },
  watch: {
    logged(val) {
      if (val) {
        this.getSystem();
        this.reload();
      }
    },
  },
  created() {
    //如果URL里有otoken和uid，则是微信登录。
    this.otoken = this.getUrlAttr("otoken");
    this.uid = this.getUrlAttr("uid");
    this.invite = this.getUrlAttr("invite");
    //如果入口是下载页（app分享出来的），但没有邀请码，直接跳到下载页
    if (this.$route.name === "Download" && !this.invite) {
      location.replace(this.dlpageURL);
      return false;
    }
    //如果邀请码跟旧邀请码不一样，写入新邀请码，并且重置绑定次数
    if (
      this.invite &&
      this.invite.length > 5 &&
      this.invite !== localStorage.getItem("invite")
    ) {
      localStorage.setItem("invite", this.invite);
      localStorage.setItem("bindTimes", 0);
    }
    //初始化
    this.init();
  },
  mounted() {},
  methods: {
    reload() {
      this.isAlive = false;
      this.$nextTick(() => {
        this.isAlive = true;
      });
    },
    toLogin() {
      let hash = location.hash;
      //hash里面可能会有问号
      let path = hash.split("?")[0];
      //去获取otoken
      location.href =
        this.$global.wxLoginUrl +
        encodeURIComponent("path=" + encodeURIComponent(path)) +
        "&state=" +
        this.invite;
    },
    getUrlAttr(attrName) {
      let attr = decodeURIComponent(
        (new RegExp("[?|&]" + attrName + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      );
      return attr || null;
    },
    init() {
      let uuid = localStorage.getItem("uuid"); //没有就不传
      this.$ajax
        .post("/account/session/init/", {
          donotNeedToken: true,
          uuid: uuid,
          source: 0,
          version: "h5", //客户端版本
        })
        .then((res) => {
          //由服务器给每个设备分配的唯一设备码
          localStorage.setItem("uuid", res.uuid);
          //otoken的判断一定在最前，如果otoken已经用过就不走微信登录
          if (this.otoken && this.otoken !== localStorage.getItem("otoken")) {
            this.wxLogin();
          } else if (res.state === 0) {
            //0：未登 1：需刷新token，2：已登
            this.toLogin(); //未登录则跳到登录界面，
          } else if (res.state === 1) {
            //需刷新Token
            this.refresh();
          } else if (res.state === 2) {
            //已登录
            if (this.token) {
              //console.log("不用登录");
              this.logged = true;
            } else {
              this.toLogin();
            }
          }
        });
    },
    refresh() {
      this.$ajax
        .post(
          "/account/login/refresh/",
          { refresh_token: localStorage.getItem("refreshToken") },
          "loading"
        )
        .then((res) => {
          //console.log("刷新登录");
          localStorage.setItem("token", res.access_token);
          this.logged = true;
        })
        .catch(() => {
          localStorage.setItem("token", null);
          this.toLogin();
        });
    },
    wxLogin() {
      this.$ajax
        .post(
          "/account/login/otoken/",
          {
            donotNeedToken: true,
            uid: this.uid,
            otoken: this.otoken,
          },
          "loading"
        )
        .then((res) => {
          //console.log("微信登录");
          //写入otoken，目的是为了即使url里有otoken也不会重复登录
          localStorage.setItem("otoken", this.otoken);
          localStorage.setItem("token", res.access_token);
          localStorage.setItem("refreshToken", res.refresh_token);
          //通知全局已登录成功
          this.logged = true;
        });
    },
    getSystem() {
      let init = 0;
      if (this.$route.name === "Box" || this.$route.name === "My") {
        init = 1;
      }
      this.$ajax
        .post("/account/system/index/", {
          init: init,
        })
        .then((res) => {
          //已经得到系统信息
          this.gotSystem = true;
          //写入角标，1：购物车角标; 2：消息角标; 3：盒柜角标; 4：任务角标
          let labels = ["CartBadge", "NoteBadge", "BoxBadge", "TaskBadge"];
          res.badges.forEach((badge, i) => {
            let num = badge.num > 0 ? badge.num : null;
            this.$store.commit(labels[badge.type - 1], num);
          });
          //写入个人信息
          this.$store.commit("System", res.data);
          this.$store.commit("User", res.data.user);
          this.$store.commit("Coin", res.data.coin);
          this.$store.commit("HomePage", res.home_page);
          //写入客服信息
          this.$store.commit("Service", {
            home: res.cs_home,
            wechat: res.cs_wechat,
          });
          //弹出广告
          if (res.alerts && res.alerts.length > 0) {
            this.showBeginner = true;
            this.beginnerAlert = res.alerts[0];
          }
          //拉取代理信息
          this.getAgent();
        });
    },
    getAgent() {
      //拉取代理信息
      this.$ajax
        .post("/agent/agent/get", { uid: this.User.uid })
        .then((res) => {
          let agent = res.data;
          this.$store.commit("Agent", agent);
          //如果链接里有邀请码 & 没有上级(未绑定过) & 不是代理 { 执行自动绑定 }
          if (
            this.invite &&
            this.invite.length > 5 &&
            agent.superior === 0 &&
            agent.role === 0
          ) {
            this.autoBind();
          } else if (this.$route.name === "Download") {
            location.replace(this.dlpageURL);
          }
        });
    },
    autoBind() {
      this.$ajax
        .post("/agent/agent/bind/", { invite_code: this.invite }, "bind")
        .then((res) => {
          this.$store.commit("Agent", res.data);
          if (this.$route.name === "Download") {
            location.replace(this.dlpageURL);
          }
        })
        .catch(() => {
          let bindTimes = localStorage.getItem("bindTimes") || 0;
          if (bindTimes < 3) {
            this.$dialog
              .alert({ message: "系统初始化失败，请重试。" })
              .then(() => {
                bindTimes++;
                localStorage.setItem("bindTimes", bindTimes);
                location.reload();
              });
          }
        });
    },
  },
};
</script>

<style src="./assets/css/base.css"></style>
<style lang="stylus">
@import './assets/css/style.styl'
@import './assets/css/iphone5.styl'
</style>
