<template>
  <van-address-edit
    :address-info="addr4vant"
    :area-list="areaList"
    show-set-default
    :area-columns-placeholder="['请选择', '请选择', '请选择']"
    @save="onSave"
  />
</template>


<script>
import Bus from "@/api/bus.js";
import { areaList } from "../api/area.js";
export default {
  name: "EditAddr",
  props: {
    addrProp: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      areaList,
      addr4vant: {},
    };
  },
  watch: {
    addrProp: {
      immediate: true,
      handler(val) {
        if (val) {
          let isDefault = val.get_def === 1 ? true : false;
          this.addr4vant = {
            id: val.address_id,
            name: val.contact_name,
            tel: val.mobile,
            province: val.province,
            city: val.city,
            county: val.country,
            addressDetail: val.addr,
            areaCode: String(val.area_code),
            postalCode: val.zip_code,
            isDefault: isDefault,
          };
        } else this.addr4vant = {};
      },
    },
  },
  created() {},
  methods: {
    onSave(o) {
      let addrID = this.addrProp ? o.id : null;
      let isDefault = o.isDefault ? 1 : 0;
      let data = {
        address_id: addrID,
        contact_name: o.name,
        area_code: o.areaCode * 1,
        province: o.province,
        city: o.city,
        country: o.county,
        addr: o.addressDetail,
        mobile: o.tel,
        get_def: isDefault,
      };
      let afterUrl = this.addrProp ? "update" : "add";
      this.$ajax
        .post(`/user/address/${afterUrl}/`, data, "loading")
        .then((res) => {
          //在地址列表里创建
          Bus.$emit("addrUpdateOnBus", res.data);
          //在预收单直接创建
          Bus.$emit("createSuccessOnBus", res.data);
          this.addr4vant = {};
        });
    },
  },
};
</script>